import { Button, Card, Col, Row } from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar'

const PlacesPage = () => {

    const matchedPlaces = [
        {
            placeId: "plc1",
            placeName: "Place 1",
            placeAddress: "1231 Main St",
            placePricepoint: "$$",
            placeDelivers: true,
            placeReviews: 4
        },
        {
            placeId: "plc2",
            placeName: "Place 2",
            placeAddress: "1232 Main St",
            placePricepoint: "$$$",
            placeDelivers: false,
            placeReviews: 2
        },
        {
            placeId: "plc3",
            placeName: "Place 3",
            placeAddress: "1233 Main St",
            placePricepoint: "$",
            placeDelivers: true,
            placeReviews: 1
        },
        {
            placeId: "plc4",
            placeName: "Place 4",
            placeAddress: "1234 Main St",
            placePricepoint: "$$",
            placeDelivers: true,
            placeReviews: 4
        },
        {
            placeId: "plc5",
            placeName: "Place 5",
            placeAddress: "1235 Main St",
            placePricepoint: "$$$",
            placeDelivers: true,
            placeReviews: 4
        },
    ]

    return (
        <>
            <div className='container text-center'>
                <NavigationBar />
                <Row xs={1} md={2} lg={3} xl={4} className="g-4">
                    {matchedPlaces.map((place, idx) => {
                        return (
                            <Col key={idx}>
                                <Card style={{ width: '18rem', margin: '10px' }}>
                                    <Card.Img variant="top" src="/img/100px180.jpg" />
                                    <Card.Body>
                                        <Card.Title>{place.placeName}</Card.Title>
                                        <Card.Text>
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <Button variant="primary">Go somewhere</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </>
    )
}

export default PlacesPage