import NavigationBar from '../components/NavigationBar'
import SlideShow from '../components/SlideShow'

const HomePage = () => {

    return (
        <>
            <div className='container text-center'>
                <NavigationBar />
                <SlideShow /> 
            </div>
        </>
    )
}

export default HomePage