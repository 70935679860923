import { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, InputGroup, Row } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

const spanStyle = {
  padding: '20px',  
}

const divStyle = {
  display: 'grid',
  alignItems: 'end',
  justifyContent: 'left',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  borderRadius: '20px',
  padding: '20px'
}
const slideImages = [
  {
    url: '/img/slide-1.jpg',
    caption: 'Enjoy home cooked yummy Broccoli Pulao Enter your zip code to begin',
    imgHeight: '480px',

  },
  {
    url: '/img/slide-2.jpg',
    caption: 'Savor fresh home made flavorful Chana Rice Enter your zip code to begin',
    imgHeight: '480px',
  }, 

];

const Slideshow = () => {

  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      navigate('/zip-submit');
    }

    setValidated(true);
  };

  const sliderProps = {
    duration: 5000,
    transitionDuration: 500,
    arrows: false,
    infinite: true,
    easing: "ease",    
  };


  return (
    <div className="slide-container">
      <Slide {...sliderProps}>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div style={{ ...divStyle, 'height': `${slideImage.imgHeight}`, 'backgroundImage': `url(${slideImage.url})` }}>
              <div style={{ display: 'grid', width: '70%', textAlign: 'left' }}>
                <span style={spanStyle}>{slideImage.caption}</span>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className='mb-3'>
                    <Form.Group as={Row} md="4" controlId="validationCustom01">
                    <InputGroup className='mb-3'>
                        <Form.Control required style={{ width: '50%', marginLeft: '5%' }} type="text" placeholder="Enter zipcode" />                    
                        <Button variant="dark" type='submit' >Get home food</Button>
                        <Form.Control.Feedback style={{ width: '50%', marginLeft: '5%' }}  type="invalid">
                        Please enter a zipcode.
                      </Form.Control.Feedback>
                      </InputGroup>

                    </Form.Group>
                  </Row>
                </Form>

              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  )
}

export default Slideshow;